import "./css/core.css";
import "./css/skin.css";

const jquery = require("jquery");

window.$ = window.jQuery = jquery;

require("./js/jquery.tm.swipe");
require("./js/timber");
require("./js/jquery.tm.avalanche");
require("./js/jquery.tm.summit");
require("./js/jquery.tm.snowbridge");
require("./js/jquery.tm.horizon");
require("./js/jquery.tm.retinize");
require("./js/jquery.tm.equalize");
require("./js/jquery.tm.counter");
require("./js/jquery.tm.preloadpage");

require("./js/packaged/jquery-ui");
// require("./js/packaged/mediaelement");

require("./js/template-functions");
